import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ColorPalette, Definition, ShieldCheckIcon, ShieldIcon, useTooltip } from "yuka";

import { ClientProfileLink } from "utils/routing";

const StyledValue = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

/**
 * Renders a single participant linked to relevant profiles as much as possible
 *
 * @param {object} props
 * @returns {React.Element}
 */
const SourceDefinition = ({ source, sourceRole }) => {
  const ref = React.useRef(null);
  const kycRef = React.useRef(null);
  let participantString;
  let participantElement;

  const individualContent = source.individualXeSourceId ? (
    <ClientProfileLink
      name={source.individualName}
      url={`/clients/${source.individualXeSourceId}`}
    />
  ) : (
    source.individualName
  );
  const entityContent = source.entityXeSourceId ? (
    <ClientProfileLink name={source.entityName} url={`/clients/${source.entityXeSourceId}`} />
  ) : (
    source.entityName
  );

  if (source.individual) {
    if (source.entity) {
      participantElement = (
        <>
          {individualContent}
          <span> @ </span>
          {entityContent}
        </>
      );
      participantString = `${source.individualName} @ ${source.entityName}`;
    } else {
      participantElement = individualContent;
      participantString = source.individualName;
    }
  } else if (source.entity) {
    participantElement = entityContent;
    participantString = source.entityName;
  }
  const tooltip = useTooltip(ref, participantString);
  const kycTooltip = useTooltip(
    kycRef,
    source.isKycApproved ? "KYC Approved" : "KYC has not been approved"
  );
  const value = (
    <StyledValue>
      <span ref={ref}>
        {participantElement}
        {tooltip}
      </span>
      <span ref={kycRef}>
        {source.isKycApproved ? (
          <ShieldCheckIcon color={ColorPalette.buy} />
        ) : (
          <ShieldIcon color={ColorPalette.white50} />
        )}
        {kycTooltip}
      </span>
    </StyledValue>
  );

  return <Definition label={sourceRole} value={value} />;
};

SourceDefinition.propTypes = {
  source: PropTypes.shape({
    individual: PropTypes.number,
    individualName: PropTypes.string,
    individualXeSourceId: PropTypes.string,
    isKycApproved: PropTypes.bool,
    entity: PropTypes.number,
    entityName: PropTypes.string,
    entityXeSourceId: PropTypes.string,
    ticketAccess: PropTypes.string.isRequired,
  }),
  sourceRole: PropTypes.string.isRequired,
};

export default SourceDefinition;
